<template>
    <v-footer
      id="footer"
      padless
      absolute>
      <v-row
        justify = "center"
        no-gutters>
        <v-card
          flat
          class = "text-center"
          color = "grey lighten-4">
          <v-card-text>
            <v-btn 
              small
              @click="showAdress = !showAdress"
              outlined>
              Impressum
            </v-btn> 

            <v-dialog v-model="showAdress" width="500">
              <v-card>  
                <v-card-title class="headline grey lighten-2">
                  Impressum
                </v-card-title>
                <v-card-text>
                  <p class="linebreak text-justify">
                  Contact: <br/>
                  Elena Malz <br/>
                  elena7charlotte@gmail.com  <br/> <br/>

                  Copy Right: <br/>
                  Any site content, as texts or graphics, is owned by the author and is not allowed to be copied or used anywhere else.

                  </p>
                </v-card-text>
              </v-card>

            </v-dialog> 
          </v-card-text>
        </v-card>
      </v-row>
    </v-footer>
</template>
<script>
export default {
  name: "Footer",
  data() {
      return {
        showAdress: false
      }
  }
}
</script>